<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-2">ADI and Spec Grading Quiz</h2>

      <p>Watch the following video and answer the associated quiz questions.</p>
      <p>
        <iframe
          title="embedded content"
          src="https://uci.yuja.com/V/Video?v=3386339&amp;node=11309678&amp;a=1414101243&amp;preload=false"
          style="width: 93%; aspect-ratio: 560/315"
          allowfullscreen="allowfullscreen"
          webkitallowfullscreen="webkitallowfullscreen"
          mozallowfullscreen="mozallowfullscreen"
        ></iframe>
      </p>

      <div class="checkbox-group mb-0">
        <v-checkbox
          v-for="option in options"
          :key="'pt-1-' + option.value"
          :value="option.value"
          class="mb-n4"
          v-model="inputs.input1"
          :label="option.text"
          dense
        />
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'UCIrvineAdiSpecGradingQuizQ0',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
      },
      options: [
        {
          text: 'I have watched the video and understand that I must complete the remaining tasks on this quiz. Note that you can return to this task at anytime.',
          value: 'agreed',
        },
      ],
    };
  },
};
</script>
<style scoped></style>
